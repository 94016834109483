.basic-table {
  width: 100%;
  width: calc(100% + 30px);
  padding: 0;
  margin: 0 -7.5px;
  border: none;
  border-collapse: collapse;
}
.basic-table thead {
  user-select: none;
}
.basic-table th {
  padding: 15px 7.5px;
}
.basic-table td {
  padding: 5px 7.5px;
}
.basic-table tr > :first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.basic-table:not(.with-action) tr > :last-child,
.basic-table .hover-end {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.basic-table th,
.basic-table td {
  text-align: right;
  line-height: 1em;
}
.basic-table th.center,
.basic-table td.center {
  text-align: center;
}
.basic-table th.left,
.basic-table td.left {
  text-align: left;
}
.basic-table tbody tr:hover > td:not(.action) {
  background: rgb(230,230,240);
}
.basic-table tbody tr.hover:not(:hover) > td:not(.action) {
  background: rgb(240,240,250);
}
.basic-table .action {
  width: 30px;
  text-align: right;
  padding: 0;
  padding-left: 10px;
}
.basic-table .action > * {
  display: inline-flex;
  opacity: 0;
  pointer-events: none;
}
.basic-table tr:hover .action > *,
.basic-table tr.hover .action > * {
  opacity: 1;
  pointer-events: initial;
}
.basic-table .mute {
  opacity: .5;
}
.basic-table .link {
  position: relative;
  cursor: pointer;
}
.basic-table .link:before,
.basic-table .link:after {
  content: '';
  display: block;
  background: rgb(118,118,130);
  width: 0;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
  opacity: .2;
  transition: width .3s cubic-bezier(.12,.845,.305,1);
}
.basic-table tr:hover .link:before,
.basic-table tr.hover .link:before {
  opacity: .3;
}
.basic-table .link:after {
  opacity: 1;
}
.basic-table .link:before,
.basic-table .link:hover:after {
  width: 100%;
}
.basic-table .tag-group {
  padding: 0;
}
.basic-table tr:not(:hover) .tag-group .tag.dark {
  background: rgb(230,230,240);
}
.basic-table .status-flag {
  width: 25px;
  padding-right: 0;
}

.basic-table .status-flag .flag {
  height: 1em;
  width: 1em;
  background: rgb(200,200,210);
  border-radius: 50%;
}
.basic-table .status-flag .flag.orange {
  background: orange;
}
.basic-table .status-flag .flag.green {
  background: limegreen;
}

.basic-table + .infinite-loader {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.infinite-loader .sk-folding-cube {
  width: 25px;
  height: 25px;
}
.infinite-loader .message {
  font-size: 12px;
  font-weight: 600;
  color: rgb(200,200,215);
  text-transform: capitalize;
  animation: fadein .25s;
}

.file-dropzone {
  text-align: center;
  height: 100px;
  width: 100%;
  padding: 10px;
  border: 2px dashed #8d939e;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}
.file-dropzone > div {
  transition: transform .1s;
}
.file-dropzone:hover > div {
  transform: scale(1.1)
}

.admin-input-pair {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.2em;
}
.admin-input-pair.stacked {
  flex-direction: column;
  margin-bottom: 10px;
}
.admin-input-pair + .admin-input-pair {
  margin-top: 5px;
}
.admin-input.disabled,
.admin-label.disabled,
.admin-checkbox.disabled,
.admin-input-pair.disabled {
  pointer-events: none;
  opacity: .3;
}
.admin-input,
.admin-label,
.admin-checkbox,
.admin-value {
  padding: .5em 1em;
  color: rgb(118,118,130);
}
.admin-input,
.admin-value,
.admin-checkbox,
.admin-dropdown.celect label {
  font-size: inherit;
  display: block;
  height: 100%;
  width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  border: 2px solid rgb(245,245,255);
  border-radius: 10px;
  outline: none;
  color: black;
  background: transparent;
  align-self: flex-start;
  line-height: normal;
}
.admin .gsd-page .admin-input,
.admin .gsd-page .admin-checkbox,
.admin .gsd-page .admin-dropdown.celect label {
  border-color: rgb(235,235,245);
}
.admin-input[disabled] {
  pointer-events: none;
}
.admin-input:hover,
.admin-input:focus,
.admin-dropdown.celect label:hover,
.admin-dropdown.celect.open label {
  background: rgb(245,245,255);
  color: black;
}
.admin .gsd-page .admin-input:hover,
.admin .gsd-page .admin-input:focus,
.admin .gsd-page .admin-dropdown.celect label:hover,
.admin .gsd-page .admin-dropdown.celect.open label {
  background: rgb(235,235,245);
}
.admin-label {
  width: 20%;
  max-width: 200px;
  padding-left: 0;
  font-weight: 600;
  color: rgb(118,118,130);
  line-height: 1em;
  flex-shrink: 0;
  border: 2px solid transparent;
  border-left: none;
  border-right: none;
}
.admin-value {
  border-color: transparent;
}
.admin-input-pair.stacked .admin-label {
  width: 100%;
  max-width: none;
  padding: 0 1em 5px 1em;
  padding-bottom: 5px;
}
.admin-checkbox {
  border-color: transparent;
  cursor: pointer;
  padding: 0;
  height: 34px;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.admin-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}
.admin-checkbox:not(:hover) .unchecked {
  color: rgb(200,200,215);
}
.admin-checkbox input[type="checkbox"]:checked ~ .unchecked,
.admin-checkbox input[type="checkbox"]:not(:checked) ~ .checked {
  display: none;
}
.admin-checkbox:hover .unchecked:before {
  content: "\F14A";
}
.admin-checkbox .checked,
.admin-checkbox:hover input[type="checkbox"]:not(:checked) ~ .unchecked {
  color: #24d3fd;
}
.admin-checkbox:hover .checked {
  color: rgb(200,200,215);
}
.admin-dropdown.celect {
  width: 100%
}
.admin-input-combo {
  display: flex;
  align-items: center;
  width: 100%;
}
.admin-input-combo [class*="admin-"] {
  width: 50%;
  flex-basis: 50%;
  flex-shrink: 1;
}

.admin .gsd-page-header {
  z-index: 101;
}

.admin .gsd-page-content .gsd-page-header {
  margin-top: 40px;
}

.basic-table tr.active,
.basic-table tr.active *{
  background-color: #abeeff !important;
}