.basic-screen .centered {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  flex-basis: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.verify-screen .message {
  color: rgb(180,180,190);
  margin-top: 40px;
  transition: opacity .3s linear .5s;
  pointer-events: none;
  user-select: none;
}
.verify-screen .message.hide {
  opacity: 0;
}
.verify-screen .gsd-icon-button.text {
  font-weight: 700;
}