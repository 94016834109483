.login-screen .form-window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: 100px;
}

.login-screen .form {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: opacity .2s;
}
.login-screen .fadeout {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

/*.login-screen .form input:-webkit-autofill,*/
.login-screen .form input:not(.admin-input),
.login-screen .form .message {
  display: block;
  width: 250px;
  padding: 10px;
  font-size: 16px;
  border: none;
  font-weight: 600;
  border-radius: 10px;
  overflow: hidden;
  outline: none;
  color: rgb(118,118,130);
  -webkit-text-fill-color: rgb(118,118,130);
  transition: border-radius .2s;
  -webkit-box-shadow: 0 0 0 100px white inset;
  background: white!important;
  filter: none;
}
.login-screen .form input:hover,
.login-screen .form input[type="email"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.login-screen .form input[type="password"] {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.login-screen .form input:not(.admin-input):hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.login-screen:not(.reset) [type="password"] {
  letter-spacing: .2em;
}

.login-screen .error {
  background: initial;
}

.login-screen .error input,
.login-screen .error input::placeholder,
.login-screen .form .message {
  color: #871c00!important;
  -webkit-text-fill-color: #871c00!important;
}

.login-screen input::placeholder {
  font-weight: 400;
  color: rgb(180,180,190);
  transition: opacity .1s;
}
.login-screen input:focus::placeholder {
  opacity: 0;
}

.login-screen .gsd-icon-button.text {
  width: 250px;
}
.login-screen .gsd-icon-button:before {
  border-radius: 20px;
}
.login-screen .gsd-icon-button.text:before {
  border-radius: 10px;
}
.login-screen .gsd-icon-button {
  margin: 0;
  transition: opacity .1s, transform .1s, color .1s, width .3s cubic-bezier(.12,.845,.305,1);
}
.login-screen .gsd-icon-button:before {
  transition: transform .1s, border-radius .15s;
}

.login-screen .form.fadeout + .gsd-icon-button {
  transform: translateX(-100px);
  transition: transform .5s;
  transition-delay: .25s;
}

.login-screen .form .message {
  font-weight: 500;
  font-size: 15px;
  max-width: 250px;
}

.login-screen .input {
  position: relative;
}
.login-screen .input:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 0;
  height: 3px;
  background: rgb(215,215,230);
  transition: width .3s;
}

.login-screen .button-box {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.login-screen .input:hover:before {
  width: 100%;
}

.login-screen .forgot-message {
  margin-top: 20px;
  color: rgb(180,180,190);
  padding-left: 10px;
  transition: opacity .2s;
}
.login-screen .forgot-message.confirm {
  padding: 0;
  text-align: center;
  color: rgb(118,118,130);
}

.login-screen .forgot-message span:hover {
  cursor: pointer;
  color: black;
}

.login-screen .title-message {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
  z-index: 10;
}
.login-screen .form-box + .title-message {
  margin-top: 50px;
  /*margin-bottom: 30px;*/
}

.login-screen .form .title-message {
  margin-bottom: 40px;
  font-weight: normal;
  text-align: center;
  width: 250px;
}

.login-screen .redirect {
  position: absolute;
  top: 110px;
  height: 80%;
  height: calc(100% - 110px);
  left: 0;
  width: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: opacity .2s;
  background: white;
}
.login-screen .redirect:not(.show) {
  pointer-events: none;
  opacity: 0;
  z-index: -10;
}
.login-screen .product {
  width: 30%;
  height: 400px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.login-screen .redirect > :last-child {
  margin-left: 5%;
  margin-right: 0;
}
.login-screen {
  flex-grow: 0;
  flex-shrink: 0;
}
.login-screen .title-img {
  height: 35px;
}
.login-screen .prod-img {
  height: 100px;
}
.login-screen #es_prod_img {
  height: 150px
}
.login-screen .gsd-button.large {
  font-size: 20px;
}
.login-screen .gsd-button.large:before,
.login-screen .gsd-button.large:after {
  border-radius: 20px;
}

.login-screen .mute {
  opacity: .5;
  font-weight: 700;
  margin-left: 5px;
  font-size: 12px;
}