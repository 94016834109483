html, body{
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  /*font-family: 'Open Sans', sans-serif!important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  font-size: 14px;
  font-weight: 400;
}
* {
  font-family: 'Ubuntu', sans-serif; /* 300, 400, 500, 700 */
}
*, *:before, *:after {
  box-sizing: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
::selection {
  background: rgb(215,215,225);
}

body :not(.hide-scroll)::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
body :not(.hide-scroll)::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgb(230,230,240);
  transition: color .2s ease;
}
body :not(.hide-scroll)::-webkit-scrollbar-thumb:hover {
  background: rgb(215,215,225);
}
body :not(.hide-scroll)::-webkit-scrollbar-thumb:active {
  background: rgb(200,200,210);
}
body :not(.hide-scroll)::-webkit-scrollbar-track {
  border-radius: 5px;
  /*background: rgba(0,0,100,.01);*/
}
body :not(.hide-scroll)::-webkit-scrollbar-track:hover {
  background: rgba(215,215,225,.2);
}
a {
  text-decoration: none;
  color: inherit;
}

input {
  padding: 0;
  border-width: 0;
}

input:focus {
  outline: none;
}

/* MISC CLASSES */
.clickable {
  cursor: pointer;
}

.error.def {
  background: rgb(255,188,172);
  border-color: #871c00;
  color: #871c00;
}





/* MAIN APP */
#root {
  height: 100vh;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.basic-screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.basic-screen .logo-bar {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  flex-basis: 110px;
  background: rgb(250,250,253);
}
.basic-screen svg {
  width: 150px;
}


/* ==== BUTTONS ==== */
.celect.gsd-icon-dropdown,
.celect.gsd-button-dropdown {
  display: inline-flex;
}
.gsd-button,
.gsd-icon-button,
.celect.gsd-icon-dropdown label,
.celect.gsd-button-dropdown label {
  position: relative;
  height: 35px;
  padding: 0 10px;
  margin: 2.5px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform .1s;
  z-index: 10;
  background: transparent;
  color: rgb(115,115,130);
  user-select: none;
  text-align: center;
  transition: opacity .5s, transform .1s;
}
.celect.gsd-button-dropdown label {
  padding-right: 30px;
  padding-left: 15px;
}
.celect.gsd-button-dropdown label:hover .caret {
  font-size: 1.3em;
}
.gsd-icon-button,
.celect.gsd-icon-dropdown label {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
}
.gsd-button.small, 
.celect.gsd-button-dropdown.small label {
  height: 25px;
  padding: 0 8px;
  margin: 2.5px 0;
}
.celect.gsd-button-dropdown.small label {
  padding-right: 23px;
}
.celect.gsd-button-dropdown.small label .caret {
  right: 8px;
}
.gsd-button.large,
.celect.gsd-button-dropdown.large label { 
  height: 55px;
  padding: 0 12px;
}
.celect.gsd-button-dropdown.large label {
  padding-right: 27px;
}
.gsd-icon-button.small,
.celect.gsd-icon-dropdown.small label {
  width: 30px;
  height: 30px;
}
.gsd-icon-button.large,
.celect.gsd-icon-dropdown.large label {
  height: 50px;
  width: 50px;
}

.celect.gsd-icon-dropdown label,
.celect.gsd-icon-dropdown label span,
.celect.gsd-button-dropdown label,
.celect.gsd-button-dropdown label span {
  z-index: 10;
  color: rgb(115,115,130);
  font-weight: inherit;
  font-style: normal;
}
.celect.gsd-icon-dropdown label .caret {
  display: none;
}
.gsd-icon-button.back {
  margin: 0 20px 0 0;
  color: black;
}
.gsd-button:hover,
.gsd-button.hover,
.gsd-icon-button:hover,
.gsd-icon-button.hover,
.celect.gsd-icon-dropdown label:hover,
.celect.gsd-icon-dropdown label:hover span,
.celect.gsd-icon-dropdown.hover label,
.celect.gsd-icon-dropdown.hover label span,
.celect.gsd-icon-dropdown.open label,
.celect.gsd-icon-dropdown.open label span,
.celect.gsd-button-dropdown label:hover,
.celect.gsd-button-dropdown label:hover span,
.celect.gsd-button-dropdown.hover label,
.celect.gsd-button-dropdown.hover label span,
.celect.gsd-button-dropdown.open label,
.celect.gsd-button-dropdown.open label span {
  color: black;
}
.gsd-icon-button:active,
.celect.gsd-icon-dropdown label:active,
.gsd-icon-button:active:hover,
.celect.gsd-icon-dropdown label:active:hover {
  transform: scale(0.8);
}
.gsd-button:active,
.celect.gsd-button-dropdown label:active,
.gsd-button:active:hover,
.celect.gsd-button-dropdown label:active:hover {
  transform: scale(0.9);
}
.gsd-button:before,
.gsd-icon-button:before,
.celect.gsd-icon-dropdown label:before,
.celect.gsd-button-dropdown label:before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background: rgb(230,230,240);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  transform: scale(0);
  transition: transform .1s;
  z-index: -2;
}
.gsd-button,
.celect.gsd-button-dropdown label {
  border-radius: 10px;
}
.gsd-button.dark:hover:before,
.gsd-button.dark.hover:before,
.gsd-icon-button.dark:hover:before,
.gsd-icon-button.dark.hover:before,
.celect.gsd-icon-dropdown.dark label:hover:before,
.celect.gsd-icon-dropdown.dark.hover label:before,
.celect.gsd-button-dropdown.dark label:hover:before,
.celect.gsd-button-dropdown.dark.hover label:before {
  background: rgb(215,215,225);
}
.gsd-icon-button,
.gsd-icon-button:before,
.celect.gsd-icon-dropdown label,
.celect.gsd-icon-dropdown label:before {
  border-radius: 50%;
}
.gsd-button:before,
.gsd-icon-button:hover:before,
.gsd-icon-button.hover:before,
.gsd-icon-button.bg:before,
.celect.gsd-icon-dropdown label:hover:before,
.celect.gsd-icon-dropdown.hover label:before,
.celect.gsd-icon-dropdown.open label:before,
.celect.gsd-button-dropdown label:before,
.celect.gsd-button-dropdown.open label:before {
  transform: scale(1);
}
.gsd-button:not(:hover):not(.hover):not(.dark):not(.active):before,
.gsd-icon-button.bg:not(:hover):not(.hover):not(.dark):not(.active):before,
.celect.gsd-icon-dropdown.bg:not(.hover):not(.open):not(.dark):not(.active) label:not(:hover):before,
.celect.gsd-button-dropdown:not(.hover):not(.open):not(.dark):not(.active) label:not(:hover):before {
  background: rgb(240,240,250);
  transform: scale(1);
}
.gsd-button,
.gsd-button > *,
.gsd-icon-button i,
.celect.gsd-icon-dropdown label,
.celect.gsd-icon-dropdown label span,
.celect.gsd-button-dropdown label,
.celect.gsd-button-dropdown label span {
  z-index: 10;
  font-size: 12px;
}
.gsd-button,
.gsd-button > *,
.celect.gsd-icon-dropdown label,
.celect.gsd-icon-dropdown label span,
.celect.gsd-button-dropdown label,
.celect.gsd-button-dropdown label span {
  font-weight: 700;
}
.gsd-icon-button i,
.celect.gsd-icon-dropdown label,
.celect.gsd-icon-dropdown label span {
  font-size: 18px;
}
.gsd-button.small,
.gsd-button.small > *,
.celect.gsd-button-dropdown.small label,
.celect.gsd-button-dropdown.small label span {
  font-size: 11px;
}
.gsd-button.large,
.gsd-button.large > *,
.celect.gsd-button-dropdown.large label,
.celect.gsd-button-dropdown.large label span {
  font-size: 17px;
}
.gsd-icon-button.small i,
.celect.gsd-icon-dropdown.small label,
.celect.gsd-icon-dropdown.small label span {
  font-size: 13px;
}
.gsd-icon-button.large i,
.celect.gsd-icon-dropdown.large label,
.celect.gsd-icon-dropdown.large label span {
  font-size: 23px;
}
.gsd-button.disabled,
.gsd-icon-button.disabled,
.celect.gsd-icon-dropdown.disabled,
.celect.gsd-button-dropdown.disabled {
  opacity: .3;
  pointer-events: none;
}
.gsd-button.disabled.with-pointer-events,
.gsd-icon-button.disabled.with-pointer-events,
.celect.gsd-icon-dropdown.disabled.with-pointer-events,
.celect.gsd-button-dropdown.disabled.with-pointer-events {
  pointer-events: initial;
}
.gsd-button.disabled.with-pointer-events:before,
.gsd-icon-button.disabled.with-pointer-events:before,
.celect.gsd-icon-dropdown.disabled.with-pointer-events:before,
.celect.gsd-button-dropdown.disabled.with-pointer-events:before,
.gsd-button.disabled.with-pointer-events:after,
.gsd-icon-button.disabled.with-pointer-events:after,
.celect.gsd-icon-dropdown.disabled.with-pointer-events:after,
.celect.gsd-button-dropdown.disabled.with-pointer-events:after {
  display: none;
}
.gsd-button.active:before,
.gsd-icon-button.active:before,
.celect.gsd-icon-dropdown.active label:before,
.celect.gsd-button-dropdown.active label:before {
  background: #c7e4fa;
}
.gsd-button.active,
.gsd-icon-button.active,
.celect.gsd-icon-dropdown.active label,
.celect.gsd-icon-dropdown.active label span,
.celect.gsd-button-dropdown.active label,
.celect.gsd-button-dropdown.active label span {
  color: #1072bd;
}
.gsd-button.active.dark,
.gsd-icon-button.active.dark,
.celect.gsd-icon-dropdown.active.dark label,
.celect.gsd-icon-dropdown.active.dark label span,
.celect.gsd-button-dropdown.active.dark label,
.celect.gsd-button-dropdown.active.dark label span {
  color: #0b568f;
}
.gsd-button.active:hover,
.gsd-button.active.hover,
.gsd-icon-button.active:hover,
.gsd-icon-button.active.hover,
.celect.gsd-icon-dropdown.active label:hover,
.celect.gsd-icon-dropdown.active label:hover span,
.celect.gsd-icon-dropdown.active.hover label,
.celect.gsd-icon-dropdown.active.hover label span,
.celect.gsd-icon-dropdown.active.open label,
.celect.gsd-icon-dropdown.active.open label span,
.celect.gsd-button-dropdown.active label,
.celect.gsd-button-dropdown.active label span {
  color: #0b568f;
}
.gsd-button.error:before,
.gsd-button.error:hover:before,
.gsd-button.error.hover:before,
.gsd-icon-button.error:hover:before,
.celect.gsd-icon-dropdown.error:hover label:before,
.celect.gsd-button-dropdown.error:hover label:before {
  background: rgb(255,188,172);
}
.gsd-button.error,
.gsd-button.error.hover,
.gsd-button.error:hover,
.gsd-icon-button.error,
.gsd-icon-button.error.hover,
.gsd-icon-button.error:hover,
.celect.gsd-icon-dropdown.error label,
.celect.gsd-icon-dropdown.error label span,
.celect.gsd-button-dropdown.error label,
.celect.gsd-button-dropdown.error label span {
  color: #871c00;
}
.gsd-icon-button.error.bg:not(:hover):before,
.celect.gsd-icon-dropdown.error.bg:not(:hover):not(.open) label:before,
.celect.gsd-button-dropdown.error label:before {
  background: rgba(255,188,172,.5);
}
.gsd-button.active:after,
.gsd-icon-button.active:after,
.celect.gsd-icon-dropdown.active label:after,
.celect.gsd-button-dropdown.active label:after {
  content: '';
  display: block;
  background: #c7e4fa;
  opacity: .4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: -1;
}
.gsd-button.active.noBg:after,
.gsd-icon-button.active.noBg:after,
.celect.gsd-icon-dropdown.active.noBg label:after,
.celect.gsd-button-dropdown.active.noBg label:after {
  background: transparent;
}
.gsd-icon-button.active:after,
.celect.gsd-icon-dropdown.active label:after {
  border-radius: 50%;
}
.gsd-icon-button.active label .spinner,
.celect.gsd-icon-dropdown.active label .spinner {
  background: #0b568f;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  } 100% {
    transform: scale(1.0);
    opacity: 0;
  }
}

.dev-tag {
  position: fixed;
  top: 33px;
  left: 0;
  width: 60px;
  height: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: orange;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dev-tag:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 20px;
  position: absolute;
  color: brown;
}
.dev-tag[data-server="local"] { display: none; }
.dev-tag[data-server="pc"]:before { content: "\f5fc"; }
.dev-tag[data-server="staging"]:before { content: "\f85d"; }
.dev-tag[data-server="prod"]:before { content: "\f57d"; }
.dev-tag[data-server="local"]:before { content: "\f120"; }